<template>
    <ar-modal 
        id="listing-modal"
        :modal_width="showSuccessConfirmation ? '613px' : isBothClosed && !in_case ? '70%' : '90%'"
        :showModalTopNav="!showSuccessConfirmation"
        :showSuccessMessage="showSuccessConfirmation"
    >
        <template v-slot:top-btn v-if="!loading && !showSuccessConfirmation">
            <template v-if="isClientListingReviewAndShowLess">
                <ar-button
                    a_class="a-btn-text mr-2"
                    data-dismiss="modal"
                    id="dismiss-modal"
                    icon="fas fa-chevron-left">
                    {{ $t('Back to Products') }}
                </ar-button>
                <ar-button 
                    @click="showMoreClientListingReview = !showMoreClientListingReview"
                    a_class="a-btn-text ml-3 float-right"
                    icon="fas fa-list">
                    Show {{showMoreClientListingReview ? 'Less ' : 'More'}} 
                </ar-button>
            </template>
            <template v-else>
                <ar-button
                    a_class="a-btn-text mr-2"
                    data-dismiss="modal"
                    id="dismiss-modal"
                    icon="fas fa-chevron-left">
                    {{ isBothClosed ? 'Back' : 'Back to Products' }}
                </ar-button>
                <ar-button 
                    v-if="isClientListingReview"
                    @click="showMoreClientListingReview = !showMoreClientListingReview"
                    a_class="a-btn-text ml-3 float-right"
                    icon="fas fa-list">
                    Show {{showMoreClientListingReview ? 'Less ' : 'More'}} 
                </ar-button>
                <ar-button
                    @click="in_case ? edit_details_function() : edit_listing_data()"
                    a_class="a-btn-text float-right cm-link-edit-listing"
                    v-restrict="'cm-link-edit-listing'"
                    icon="fas fa-pen">
                    Edit Details
                </ar-button>
                <!-- v-if="!status_change" -->
                <ar-button
                    v-if="hasStatusChangeOptions"
                    @click="switchStatusAndHistory(true,'changed_status');isClicked_ChangedStatus = !isClicked_ChangedStatus"
                    a_class="a-btn-text mr-3 float-right"
                    icon="fas fa-sync">
                    <!-- "Changed Status" in listings "History" in Cases -->
                    {{isClicked_ChangedStatus ? 'Hide ' : 'Show '}} {{ button_label }}
                </ar-button>
                <!-- v-else-if="status_change" -->
                <!-- <ar-button
                    @click="switchStatusAndHistory(false,'status_history');isClicked_StatusHistory = !isClicked_StatusHistory"
                    a_class="a-btn-text mr-3 float-right"
                    icon="fas fa-redo">
                    {{isClicked_StatusHistory ? 'Hide ' : 'Show '}} {{ !in_case ? 'Status History Test' : 'Case Details' }}
                </ar-button> -->
                <template v-if="$featureAccess.isAllowed('cm-button-show-status-history')">
                    <ar-button
                        v-if="!in_case"
                        a_class="a-btn-text mr-3 float-right"
                        icon="fas fa-redo"
                        @click="switchStatusAndHistory(false, 'status_history'); isClicked_StatusHistory = !isClicked_StatusHistory"
                    >
                        {{ isClicked_StatusHistory ? 'Hide' : 'Show' }} Status History 
                    </ar-button>
                </template>

                <ar-button
                    v-if="in_case"
                    a_class="a-btn-text mr-3 float-right"
                    icon="fas fa-redo"
                    @click="switchStatusAndHistory(false, 'status_history'); isClicked_StatusHistory = !isClicked_StatusHistory"
                >
                    {{ isClicked_StatusHistory ? 'Hide' : 'Show' }} Case Details
                </ar-button>
            </template>
        </template>
        <!-- <template v-if="showSuccessConfirmation">
            <div class="row justify-content-center success-confirmation text-center">
                <img src="/static/svg/WR_Success.svg" class="animated zoomIn">
                <h4 class="w-100 mt-3 animated fadeIn slower" style="margin-top: 146px !important;">{{ successMessage }}</h4>
                <p class="text-muted">Getting the next one.. Please wait..</p>
            </div>
        </template> -->
        <template v-if="isClientListingReview && loading">
            <ArLoaderinator :loading="loading" style="min-height: 520px"/>
        </template>
        <template v-else-if="loading">
            <div class="listing-loading-state">
                <img src="/static/img/Modal-Loader.gif" alt="loading">
            </div>
        </template>
        <template v-else-if="!loading">
            <div class="animated fadeIn">
                <a href="javascript:void(0)" v-if="showLeftCLRNav" @click="$emit('clickCLRLeft')" class="client-design-navs left"><i class="fas fa-chevron-left"></i></a>
                <a href="javascript:void(0)" v-if="showRightCLRNav" @click="$emit('clickCLRRight')" class="client-design-navs right"><i class="fas fa-chevron-right"></i></a>
                <section class="d-flex justify-between" v-if="isClientListingReviewAndShowLess">
                    <div class="w-100 lf-review-preview">
                        <!-- <template v-if="status_change && ['rejected', 'accepted', 'monitoring'].includes(status) && user.id != userGroundWorks"> -->
                        <!-- removed "status_change &&"  -->
                        <template v-if="status && (!['accepted', 'initial-review-completed'].includes(status) || isInFiledTab)">
                            <div v-if="status !== 'accepted' || isInFiledTab" class="p-3">
                                <h4 class="mb-3">{{markAsButtons[status][2]}}</h4>
                                <!-- <p class="text-muted text-left">A selection of predefined reasons are available below. Select <b>Others</b> to provide a custom reason. <br/> Confirm by clicking on the 'Confirm' button.</p> -->
                                <ar-reason-selection
                                    v-if     ="status !== 'accepted' || isInFiledTab"
                                    :reasons ="predefinedReasons"
                                    key      ="listing-modal-reasons"
                                    is-batch-modal
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="img-container">
                                <a :href="preview('listing', content.evidences['lf review'], true)" target="_blank">
                                    <img :onerror="`this.src='${errorPreview(preview('listing', preview('listing', content.evidences['lf review'])))}'`" :src="preview('listing', content.evidences['lf review'])" alt="default listing photo">
                                </a>
                            </div>
                        </template>
                    </div>
                    <div class="d-flex flex-wrap client-review-con">
                        <div class="client-review-con-con">
                            <div class="a-modal-seller-info">
                                <div class="seller-details">
                                    <!-- <img 
                                        onerror="this.src='/static/svg/Campaign Default Photo.svg'" 
                                        alt="default campaign photo"
                                        :src="preview('platforms', content.platform)"
                                    > -->
                                    <div class="d-flex align-items-center">
                                        <!-- <a :href="this.seller_url" target="_blank" class="d-inline-block" v-tooltip="`Click to view store page`">
                                            <img class="client-design-seller-image mr-2" onerror="this.src='/static/svg/Campaign Default Photo.svg'" alt="default campaign photo" :src="preview('platforms', content.platform)" />
                                        </a> -->
                                        <a :href="this.seller_url" target="_blank" class="d-inline-block" v-tooltip="`Click to view store page`">
                                            <h5 class="mb-0" :class="{'text-danger': seller_name == 'Seller Not Found'}"> {{seller_name}}</h5>
                                        </a>
                                    </div>
                                    <span class="id-status">
                                        <span class="d-inline-block position-relative align-middle">
                                            <span v-tooltip.bottom="`Research Status`" class="clr-status review">
                                                <img style="width: 10px; height: auto; position: relative; left: initial; right: initial; top: -1px; margin-right: 4px;" :src="$parent.$parent.getQFlagIcon(content.qflag_id)" alt="qflag">
                                                {{ content.status == "Qualified" ? "For Review" : content.status }}
                                            </span>
                                        </span>
                                        <span class="d-inline-block position-relative align-middle">
                                            <ar-button
                                                a_class="inverted"
                                                style="font-size: 10px; font-weight: 500; padding: 1px 5px 1px; border-radius: 4px;"
                                                v-tooltip.bottom="`Copy listing id`"
                                                @mouseover="isCopying(true)"
                                                @mouseleave="isCopying(false)"
                                                @click="copytoClipboard(content.id)"
                                            >{{ content.id }}</ar-button>
                                        </span>
                                        <span class="d-inline-block position-relative align-middle">
                                            <div
                                                class="clr-status"
                                                v-tooltip.bottom="`Campaign`"
                                            >
                                                <img :src="preview('campaigns', content.campaign)" class="position-static" alt="">
                                                <p class="m-0">{{ content.campaign }}</p>
                                            </div>
                                        </span>
                                        <span class="d-inline-block position-relative align-middle">
                                            <div
                                                class="clr-status"
                                                v-tooltip.bottom="`Platform`"
                                            >
                                                <img :src="preview('platforms', content.platform)" alt="" class="position-static" onerror="this.src='/static/svg/Campaign Default Photo.svg'">
                                                <p class="m-0">{{ content.platform }}</p>
                                            </div>
                                        </span>
                                        <span class="d-inline-block position-relative align-middle">
                                            <div
                                                class="clr-status"
                                                v-tooltip.bottom="`Price`"
                                            >
                                                <img src="/static/svg/Transaction_ItemPrice.svg" alt="" class="position-static" onerror="this.src='/static/svg/Campaign Default Photo.svg'">
                                                <p class="m-0">{{ content?.listing_info?.listing_price ?? "Price not set" }} <i style="font-style: normal;">{{ content.listing_info?.currency?.split('-')[0] }}</i> <span v-if="content.listing_info.listing_price_unit">/</span> {{ content?.listing_info?.listing_price_unit }}</p>
                                            </div>
                                        </span>
                                    </span>
                                    <p class="mt-2 mb-4">
                                        {{content.listing_info.listing_title}}
                                    </p>
                                    <!-- <p class="clr-price">
                                        <img src="/static/svg/Transaction_ItemPrice.svg" alt="" />
                                        <b><i class="mr-2">{{ getListingCurrencySymbol() }}</i>{{ content?.listing_info?.listing_price ?? "Price not set" }} <small><i class="small">{{ content.listing_info?.currency?.split('-')[0] }}</i> <span v-if="content.listing_info.listing_price_unit">/</span> {{ content?.listing_info?.listing_price_unit }}</small></b>
                                    </p> -->
                                    <section v-if="Object.entries(content.evidences).length > 0">
                                        <a class="d-block" :href="preview('listing', mergedEvidence.image, true)" target="_blank" :title="mergedEvidence.type">
                                            <div class="evidence-notice-alt mb-4 mt-0" style="height: 83px">
                                                <div class="notice-img">
                                                    <img src="/static/svg/agreement-files/PDF.svg" alt="something">
                                                </div>
                                                <p>{{ $t('In case the product and store pages are no longer available, you can check this captured evidence.') }}</p>
                                            </div>
                                        </a>
                                    </section>
                                </div>
                            </div>
                        
                            <div class="a-modal-status-history-con client-review-design mb-4">
                                <div class="animated fadeIn" v-if="hasStatusChangeOptions">
                                    <h5 class="mb-3 status-change-header text-bold">{{ $t('Select a Status change') }}</h5>
                                    <div>
                                        <ar-toggle-btn :vertical="false">
                                            <template v-for="(value,index) in markAsButtons" >
                                                <template v-if="$route.params.status != null && !value[0].includes($route.params.status)">
                                                    <div 
                                                        v-if   ="mark_as.includes(index)" 
                                                        @click ="status_changing ? null : toggleStatus(index)" 
                                                        class  ="btn statusBorder" 
                                                        :key   ="index" 
                                                        :class ="[{'disabled': status_changing}, `status-${value[1]}`, statusChangeFeatureName(value[1])]"
                                                        v-restrict="statusChangeFeatureName(value[1])"
                                                        v-tooltip.bottom="isClientListingReviewAndShowLess ? $t(value[3]) : ''"
                                                    >
                                                        <img 
                                                            :src="status == index ? 
                                                                value[0] == 'Monitoring' ? '/static/svg/Orange.svg' :
                                                                value[0] == 'Accepted' ? '/static/svg/Green.svg' :
                                                                value[0] == 'Rejected' ? '/static/svg/Red.svg' : `/static/svg/Icon_${value[0]}.svg` : 
                                                                `/static/svg/Icon_${value[0]}.svg`"
                                                            alt="icon"
                                                        >
                                                        <h5 class="d-inline-block ml-2">
                                                            {{ $t(value[0] == "InitialReviewCompleted" && isClientListingReviewAndShowLess 
                                                                ? "Initial Review Completed" 
                                                                : value[0] == "Qualified" && isClientListingReviewAndShowLess 
                                                                    ? "For Review"
                                                                    : value[2])
                                                            }}
                                                        </h5>
                                                        <p v-if="!isClientListingReview || !isClientListingReviewAndShowLess">{{value[3]}}</p>
                                                        <input 
                                                            type ="radio" 
                                                            name ="status" 
                                                            :id  ="`status-${value[1]}`"
                                                        >
                                                    </div>
                                                </template>
                                                <template v-else-if="$route.name == 'listingreview' && !value[0].includes('For Review')">
                                                    <div 
                                                        :key="index" 
                                                        v-if="mark_as.includes(index)" 
                                                        @click ="status_changing ? null : toggleStatus(index)"
                                                        class="btn statusBorder" 
                                                        :class ="[{'disabled': status_changing}, `status-${value[1]} ${statusChangeFeatureName(value[1])}`]"
                                                        v-restrict ="statusChangeFeatureName(value[1])"
                                                        v-tooltip.bottom="isClientListingReviewAndShowLess ? value[3] : ''"
                                                    >
                                                        <img :src="`/static/svg/Icon_${value[0]}.svg`" alt="icon">
                                                        <h5>
                                                            {{ value[0] == "InitialReviewCompleted" && isClientListingReviewAndShowLess 
                                                                ? "Initial Review Completed" 
                                                                : value[0] == "Qualified" && isClientListingReviewAndShowLess 
                                                                    ? "For Review"
                                                                    : value[2] }}
                                                        </h5>
                                                        <p v-if="!isClientListingReview || !isClientListingReviewAndShowLess">{{value[3]}}</p>
                                                        <input type="radio" name="status" :id="`status-${value[1]}`">
                                                    </div>
                                                </template>
                                            </template>
                                        </ar-toggle-btn>
                                        <!-- <template v-if="status == 'rejected'">
                                            <ar-textfield v-if="mark_as.includes('comment')" v-model="comment" a_type="textarea" a_style="height: 100px; resize: none;" placeholder="Add Comment"></ar-textfield>
                                        </template> -->
                                        <a
                                            v-if   ="Object.values(validListing).length"
                                            :href  ="`/lawfirm/listings/campaign/${ campaign_id }/${ qflagStatus }/0?lid=${ validListing.lid }`"
                                            class  ="text-danger text-bold animated fadeIn d-block text-center mt-5"
                                            target ="_blank"
                                        >
                                            The seller of the data(<u>{{validListing.lid}}</u>) already has a working item in this campaign. Click to check.
                                        </a>
                                    </div>
                                </div>
                                <template v-else>
                                    <i class="fas fa-info-circle text-info mr-2" /> No Status Change option available for this status
                                </template>
                            </div>
                            <ar-button 
                                v-if="hasStatusChangeOptions"
                                @click    ="updateStatus(status, comment, content.id, selected_reasons)" 
                                a_class   ="d-block w-100 mt-4" 
                                :disabled ="status_changing"
                            >
                                {{status_changing ? 'Processing...' : 'Confirm this action'}}
                            </ar-button>
                        </div>
                    </div>
                </section>
                <section v-else>
                    <div class="row">
                        <!-- class="col-md-7 col-xs-12" -->
                        <div class=" col-xs-12"
                            :class="{'col-md-12': isBothClosed && !in_case, 'col-md-7': !isBothClosed || (isBothClosed && in_case)}"
                        >
                            <div class="a-modal-seller-info">
                                <!-- <template v-if="status_change && ['rejected', 'accepted', 'monitoring'].includes(status) && user.id != userGroundWorks"> -->
                                <!-- removed "status_change &&"  -->
                                <template v-if="status && (!['accepted', 'initial-review-completed'].includes(status) || isInFiledTab)">
                                    <div v-if="status !== 'accepted' || isInFiledTab" class="p-3">
                                        <h4 class="mb-3">{{markAsButtons[status][2]}}</h4>
                                        <p class="text-muted text-left">A selection of predefined reasons are available below. Select <b>Others</b> to provide a custom reason. <br/> Confirm by clicking on the 'Confirm' button.</p>
                                        <ar-reason-selection v-if="status !== 'accepted' || isInFiledTab" :reasons="predefinedReasons" key="listing-modal-reasons" is-batch-modal/>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="img-container">
                                        <a :href="preview('listing', getPreview(content.evidences.preview, content.evidences.Preview), true)" target="_blank">
                                            <img :onerror="`this.src='${errorPreview(preview('listing', getPreview(content.evidences.preview, content.evidences.Preview)))}'`" :src="preview('listing', getPreview(content.evidences.preview, content.evidences.Preview))" alt="default listing photo">
                                        </a>
                                    </div>
                                    <div class="seller-details">
                                        <img 
                                            onerror="this.src='/static/svg/Campaign Default Photo.svg'" 
                                            alt="default campaign photo"
                                            :src="preview('platforms', content.platform)"
                                        >
                                        <h5 :class="{'text-danger': seller_name == 'Seller Not Found'}"> {{seller_name}} <span>{{content.id}} - {{content.status == "Qualified" ? "For Review" : content.status}} {{ content.qflag_id == 34 ? ` - ${content.case_number}` : '' }}</span></h5>
                                        <div v-if="$route.params.status == 'Submitted' && content.group.name" class="group d-inline-block">
                                            <img 
                                                class="group-name d-inline-block mr-2"
                                                style="height: 24px; width: 24px; position: inherit"
                                                src="/static/svg/defendants/Group_Name.svg"
                                                alt="Group Name"
                                            >
                                            <span class="d-inline align-middle text-uppercase text-primary mr-2" style="letter-spacing: 0.28px; font-weight: 600">{{ content.group.name }}</span>
                                            <img
                                                @click="show_remove_confirmation = true"
                                                class="group-name mr-2 remove-btn"
                                                src="/static/svg/Icon_Removed.svg"
                                                alt="remove icon"
                                                title="Remove data from group"
                                            >
                                        </div>
                                        <div class="remove-confirm pl-4 pr-4 pt-3 pb-3 position-absolute" :class="{'d-none': !show_remove_confirmation}">
                                            <p class="font-weight-bold mb-0">Remove this data from the group</p>
                                            <p>Do you wish to continue?</p>
                                            <ar-button @click="removeGroup()" class="mr-2">Confirm</ar-button>
                                            <ar-button @click="show_remove_confirmation = false" outlined>
                                                Cancel
                                            </ar-button>
                                        </div>
                                        <p class="mt-2 mb-4">
                                            {{content.listing_info.listing_title}}
                                        </p>
                                        <a :href="content.url" target="_blank">
                                            {{content.url}}
                                        </a>
                                        <p class="remarks-con" v-if="in_case && content.case_listing.length != 0 && content.case_listing[0].remarks != '' && content.case_listing[0].remarks != null">
                                            {{content.case_listing[0].remarks}}
                                        </p>
                                        <section v-if="Object.entries(content.evidences).length > 0">
                                            <!-- <div-row>
                                                <column :md="isBothClosed ? '12': '7'"> -->
                                                    <div class="evidence-notice">
                                                        <a class="notice-img" :href="preview('listing', mergedEvidence.image, true)" target="_blank" :title="mergedEvidence.type">
                                                            <img :onerror="`this.src='${errorPreview(preview('listing', mergedEvidence.image))}'`" :src="preview('listing', mergedEvidence.image)" alt="">
                                                        </a>
                                                        <!-- <img class="notice-img" src="/static/svg/Notice_SVG.svg" alt="notice"> -->
                                                        <h6>Notice</h6>
                                                        <p>In case the product and store pages are no longer available, you can check this captured evidence.</p>
                                                    </div>
                                                <!-- </column>
                                            </div-row>
                                            <hr class="mt-4 mb-4"> -->
                                        </section>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-if="isClicked_StatusHistory || isClicked_ChangedStatus || in_case" class="col-md-5 col-xs-12">
                            <div class="a-modal-status-history-con">
                                <template v-if="in_case">
                                    <!-- <template v-if="status_change"> -->
                                    <template v-if="!isClicked_StatusHistory">
                                        <div class="animated fadeIn">
                                            <h5 class="mb-4">Status History</h5>
                                            <div class="a-modal-status-history">
                                                <!-- <template v-if="content.general_history.length > 0"> -->
                                                <template v-if="generalHistory.length > 0">
                                                    <!-- v-for="(history, index) in content.general_history" -->
                                                    <!-- :subject="name(history.user[0] ? history.user[0].first_name : '',history.user[0] ? history.user[0].last_name : '')" -->
                                                    <div v-show="isHistoryReady">
                                                        <ar-list 
                                                            type="history"
                                                            v-for="(history, index) in generalHistory"
                                                            :subject="name(history.user[0])"
                                                            :key="index"
                                                            :details="cleanDetails(history.text)"
                                                            :date="history.created_at"
                                                            :status_icon="getStatusIcon(history.value, history.field)"
                                                            :field="history.field"
                                                            :action="history.action"
                                                        />
                                                    </div>
                                                    <div class="d-flex justify-content-center" v-if="!isHistoryReady">
                                                        <div v-for="(spin, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark ml-2 mt-5" role="status"></div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="empty-status-state">
                                                        <img src="/static/svg/Status_Photo.svg" alt="nostatus">
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="isClicked_StatusHistory">
                                        <div class="animated fadeIn">    
                                            <h5 class="mb-4">Case Details</h5>
                                            <div class="a-modal-case-details-con">
                                                <div class="pr-3">
                                                    <ar-toggle-btn>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_flag.svg" alt="icon">
                                                            <h5>
                                                                <!-- content.case_listing[0].case_flag.toLowerCase().includes('default') && isFeatureRestricted('cm-text-non-defaulted-figures') -->
                                                                {{ 
                                                                    content.case_listing[0] == null 
                                                                    || content.case_listing[0].case_flag == null 
                                                                    || content.case_listing[0].case_flag == "" 
                                                                        ? "No Agreement" 
                                                                        : content.case_listing[0].case_flag.toLowerCase().includes('default') && !access.defaults
                                                                            ? "No Agreement" 
                                                                            : content.case_listing[0].case_flag 
                                                                }}
                                                            </h5>
                                                            <p>Seller Flag</p>
                                                        </div>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_Counsel.svg" alt="icon">
                                                            <h5>{{ content.case_listing[0] == null || content.case_listing[0].counsel == null || content.case_listing[0].counsel == "" ? "Not Set" : content.case_listing[0].counsel }}</h5>
                                                            <p>Counsel</p>
                                                        </div>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_PlatformID.svg" alt="icon">
                                                            <h5>{{ content.case_listing[0] == null || content.case_listing[0].platform_account_id == null || content.case_listing[0].platform_account_id == "" ? "Not Set" : content.case_listing[0].platform_account_id }}</h5>
                                                            <p>Platform Account ID</p>
                                                        </div>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_ItemSold.svg" alt="icon">
                                                            <h5>{{ content.case_listing[0] == null || content.case_listing[0].items_sold == null || content.case_listing[0].items_sold == "" ? "Not Set" : content.case_listing[0].items_sold }}</h5>
                                                            <p>No. of Items Sold</p>
                                                        </div>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_Gross.svg" alt="icon">
                                                            <h5>{{ content.case_listing[0] == null || content.case_listing[0].gross == null || content.case_listing[0].gross == "" ? "Not Set" : content.case_listing[0].gross }}</h5>
                                                            <p>Gross Merchandise Volume</p>
                                                        </div>
                                                        <div class="btn">
                                                            <img src="/static/svg/Case_Available.svg" alt="icon">
                                                            <h5>{{ content.case_listing[0] == null || content.case_listing[0].available_items == null || content.case_listing[0].available_items == "" ? "Not Set" : content.case_listing[0].available_items }}</h5>
                                                            <p>Available Items</p>
                                                        </div>
                                                    </ar-toggle-btn>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <!-- listings -->
                                <template v-else>
                                    <!-- <template v-if="!status_change && !in_case"></template> -->
                                    <template v-if="isClicked_StatusHistory">
                                        <div class="animated fadeIn">
                                            <h5 class="mb-4">Status History</h5>
                                            <div class="a-modal-status-history">
                                                <!-- <template v-if="content.general_history.length > 0"> -->
                                                <template v-if="generalHistory.length > 0">
                                                    <div v-show="isHistoryReady">
                                                        <!-- v-for="(history, index) in content.general_history" -->
                                                        <!-- :subject="name(isEmpty(history.user[0]).first_name,isEmpty(history.user[0] ? history.user[0].last_name : ''))" -->
                                                        <ar-list 
                                                            type="history"
                                                            v-for="(history, index) in generalHistory"
                                                            :subject="name(history.user[0])"
                                                            :key="index"
                                                            :details="cleanDetails(history.text)"
                                                            :date="history.created_at"
                                                            :status_icon="getStatusIcon(history.value, history.field)"
                                                            :field="history.field"
                                                            :action="history.action"
                                                        />
                                                    </div>
                                                    <div class="d-flex justify-content-center" v-if="!isHistoryReady">
                                                        <div v-for="(_, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark ml-2 mt-5" role="status"></div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="empty-status-state">
                                                        <img src="/static/svg/Status_Photo.svg" alt="nostatus">
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <template v-else-if="!in_case"></template> -->
                                    <template v-else-if="isClicked_ChangedStatus">
                                        <template v-if="subStatusesOnlyOptions?.length">
                                            <div class="animated fadeIn pr-3">
                                                <h5 class="mb-4">Transfer to another sub status</h5>
                                                <ar-toggle-btn>
                                                    <div class="sub-statuses-container w-100">
                                                        <div 
                                                            v-for="(sub, sub_index) in subStatusesOnlyOptions"
                                                            class="btn statusBorder sub-status only d-flex align-items-center"
                                                            :key="`transfer-sub-only--${sub_index}`"
                                                            @click="toggleSubStatusOnly($event, sub.id ?? -1, keys[content?.status])"
                                                            @mousedown="removeSubStatusFocus()"
                                                        >
                                                            <h5>{{sub.label}}</h5>
                                                            <input 
                                                                type ="radio" 
                                                                :name ="`transfer-sub-only--sub-status`"
                                                                :id  ="`transfer-sub-only--${sub.id}`"
                                                                v-show="false"
                                                            >
                                                        </div>
                                                    </div>
                                                </ar-toggle-btn>
                                                <ar-button 
                                                    @click="updateListingSubStatus()"
                                                    a_class   ="float-right mb-4" 
                                                    :disabled ="status_changing"
                                                >
                                                    {{status_changing ? 'Processing...' : 'Proceed'}}
                                                </ar-button>
                                            </div>
                                            <hr style="clear: both"/>
                                        </template>
                                        <div class="animated fadeIn" v-if="hasStatusChangeOptions">
                                            <h5 class="mb-4">Select a Status change</h5>
                                            <div class="pr-3">
                                                <ar-toggle-btn>
                                                    <template v-for="(value,index) in markAsButtons" >
                                                        <div :key="`status-change-options--${index}`" class="status-change-options w-100">
                                                            <template v-if="$route.params.status != null && !value[0].includes($route.params.status == 'For Review' ? 'Qualified' : $route.params.status?.replaceAll(' ', ''))">
                                                                <div 
                                                                    v-if   ="mark_as.includes(index)" 
                                                                    @click ="toggleStatus(index)" 
                                                                    class  ="btn statusBorder w-100" 
                                                                    :key   ="index" 
                                                                    :class ="`status-${value[1]} ${statusChangeFeatureName(value[1])}`"
                                                                    v-restrict ="statusChangeFeatureName(value[1])"
                                                                >
                                                                    <img 
                                                                        :src="status == index ? 
                                                                            value[0] == 'Monitoring' ? '/static/svg/Orange.svg' :
                                                                            value[0] == 'Accepted' ? '/static/svg/Green.svg' :
                                                                            value[0] == 'Rejected' ? '/static/svg/Red.svg' : `/static/svg/Icon_${value[0]}.svg` : 
                                                                            `/static/svg/Icon_${value[0]}.svg`"
                                                                        alt="icon"
                                                                    >
                                                                    <h5>{{value[2]}}</h5>
                                                                    <p>{{value[3]}}</p>
                                                                    <input 
                                                                        type ="radio" 
                                                                        name ="status" 
                                                                        :id  ="`status-${value[1]}`"
                                                                        v-show="false"
                                                                    >
                                                                </div>
                                                                <template v-if="['forreview', 'qualified'].includes(value[1]) ? status == 'forreview' : status == value[1] && subStatuses.changeStatus[index]?.length">
                                                                    <ar-toggle-btn>
                                                                        <div :key="`sub-status--${index}`" class="sub-statuses-container w-100">
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses.changeStatus[index]"
                                                                                class="btn statusBorder sub-status d-flex align-items-center"
                                                                                :key="index + sub_index"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, index)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`${index}--sub-status`"
                                                                                    :id  ="`${index}--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </template>
                                                            <template v-else-if="$route.name == 'listingreview' && !(value[0].includes('For Review') || value[0].includes('Qualified'))">
                                                                <div 
                                                                    :key="index" 
                                                                    v-if="mark_as.includes(index)" 
                                                                    @click="toggleStatus(index)" 
                                                                    class="btn statusBorder w-100" 
                                                                    :class="`status-${value[1]} ${statusChangeFeatureName(value[1])}`"
                                                                    v-restrict="statusChangeFeatureName(value[1])"
                                                                >
                                                                    <img :src="`/static/svg/Icon_${value[0]}.svg`" alt="icon">
                                                                    <h5>{{value[2]}}</h5>
                                                                    <p>{{value[3]}}</p>
                                                                    <input type="radio" name="status" :id="`status-${value[1]}`" v-show="false">
                                                                </div>
                                                                <template v-if="['forreview', 'qualified'].includes(value[1]) ? status == 'forreview' : status == value[1] && subStatuses.changeStatus[index]?.length">
                                                                    <ar-toggle-btn>
                                                                        <div :key="`sub-status--${index}`" class="sub-statuses-container w-100">
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses.changeStatus[index]"
                                                                                class="btn statusBorder sub-status d-flex align-items-center"
                                                                                :key="index + sub_index"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, index)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`${index}--sub-status`"
                                                                                    :id  ="`${index}--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </ar-toggle-btn>
                                                <!-- <template v-if="status == 'rejected'">
                                                    <ar-textfield v-if="mark_as.includes('comment')" v-model="comment" a_type="textarea" a_style="height: 100px; resize: none;" placeholder="Add Comment"></ar-textfield>
                                                </template> -->
                                                <ar-button 
                                                    @click    ="updateStatus(status, comment, content.id, selected_reasons)" 
                                                    a_class   ="float-right" 
                                                    :disabled ="status_changing"
                                                >
                                                    {{status_changing ? 'Processing...' : 'Proceed'}}
                                                </ar-button>
                                                <a
                                                    v-if   ="Object.values(validListing).length"
                                                    :href  ="`/lawfirm/listings/campaign/${ campaign_id }/${ qflagStatus }/0?lid=${ validListing.lid }`"
                                                    class  ="text-danger text-bold animated fadeIn d-block text-center mt-5"
                                                    target ="_blank"

                                                >
                                                    The seller of the data(<u>{{validListing.lid}}</u>) already has a working item in this campaign. Click to check.
                                                </a>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <i class="fas fa-info-circle text-info mr-2"/>No Status Change option available for this status
                                        </template>
                                    </template>
                                </template>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <br><br>
                <!-- <template v-if="status != 'rejected' || (status == 'rejected' && user.id == 814)"> -->
                <!-- <template v-if="!(status && isInFiledTab) && !['rejected', /* 'accepted', */ 'monitoring', 'for-internal-review', 'haspo', 'recycled'].includes(status) && !isClientListingReviewAndShowLess"> -->
                <template v-if="!(status && isInFiledTab) && !['rejected', 'monitoring', 'for-internal-review', 'haspo', 'recycled', 'forreview', 'invalid', 'submitted', 'archived'].includes(status) && !isClientListingReviewAndShowLess">
                    <section class="animated fadeIn" v-for="(section,index) in sections()" :key="index">
                        <hr class="mt-4 mb-4" v-if="index != 'Data Information' && index != 'Infringement Indicators' && !in_case"/>
                        <hr class="mt-4 mb-4" v-if="index != 'Data Information' && index != 'Infringement Indicators' && (index != 'Payment Accounts' && in_case)"/>
                        <hr class="mt-4 mb-4" v-if="index == 'Data Information' && in_case && sections()['Payment Accounts'].length > 0"/>
                        <hr class="mt-4 mb-4" v-if="index == 'Infringement Indicators' && section[1].length > 0" />
                        <div-row  v-if="index == 'Infringement Indicators'">
                            <column md="12" v-if="section[1].length > 0">
                                <h5>{{ index }}</h5>
                                <template v-if="gettype(section[1]) !== 'undefined'">
                                    <modal container="check-info-item" :class="['a-check-item-inline', 'col-md-5']" v-for="(value,key) in section[1]" :key="key">
                                        <img :src="`/static/svg/${section[0]}.svg`" alt="icon">
                                        {{value}}
                                    </modal>
                                </template>

                                <modal v-else container="check-info-item" :class="['a-check-item-inline']">
                                    No reason has been set.
                                </modal>
                            </column>
                        </div-row>
                        <div-row  v-else-if="index == 'Payment Accounts'">
                            <column md="12" v-if="isEmpty(section).length > 0">
                                <h5>{{ index }}</h5>
                            </column>
                            <column md="4" v-for="(account,key) in isEmpty(section)" :key="key">
                                <!-- <template v-if="in_case"> -->
                                    <div class="another-payment-accounts mb-3" :class="{'main-account': account.seller_account_id ? content.seller_account_id == account.seller_account_id  : content.seller_account_id == account.id}">
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td class="header-label"><img src="/static/svg/Payment_Type.svg" alt="icon" class="mr-2">Payment Type:</td>
                                                    <td><b>{{ isEmptyWithDefault(account.type, "Not Set") }}</b></td>
                                                </tr>
                                                <tr>
                                                    <td class="header-label"><img src="/static/svg/SellerInfo_Email.svg" alt="icon" class="mr-2">Email/Name:</td>
                                                    <td><b>{{ isEmptyWithDefault(account.name_email, "Not Set") }}</b></td>
                                                </tr>
                                                <tr>
                                                    <td class="header-label"><img src="/static/svg/Account_Num.svg" alt="icon" class="mr-2">Account No.:</td>
                                                    <td><b>{{ isEmptyWithDefault(account.number, "Not Set") }}</b></td>
                                                </tr>
                                                <tr>
                                                    <td class="header-label"><img src="/static/svg/Account_Notes.svg" alt="icon" class="mr-2">Notes:</td>
                                                    <td><b>{{ isEmptyWithDefault(account.notes, "Not Set") }}</b></td>
                                                </tr>
                                                <!-- <tr>
                                                    <td class="header-label"><img src="/static/svg/Account_Location.svg" alt="icon" class="mr-2">Location.:</td>
                                                    <td><b>{{account.location || "Not Set" }}</b></td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                        <hr v-if="access.amountsDefaults && in_case"/>
                                        <!-- <hr v-if="in_case && (showDefaultedFigures || showNonDefaultedFigures)" /> -->
                                        <table class="table table-borderless" v-if="in_case">
                                            <tbody>
                                                <template v-if="access.amounts">
                                                <!-- <span v-if="showNonDefaultedFigures"> -->
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/Case_Frozen.svg" alt="icon" class="mr-2">Frozen USD:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_frozen, "Not Set")}}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/Case_Frozen.svg" alt="icon" class="mr-2">Frozen CNY:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_frozen_cny, "Not Set")}}</b></td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td class="header-label"><img src="/static/svg/Case_Released.svg" alt="icon" class="mr-2">Released:</td>
                                                        <td><b>{{account.amount_released}}</b></td>
                                                    </tr> -->
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/Case_Settled.svg" alt="icon" class="mr-2">Agreed Settlement:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_settled, "Not Set")}}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/defendants/Reports_Details_Amounts.svg" alt="icon" class="mr-2">Received Settlement:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_received_settlement, "Not Set")}}</b></td>
                                                    </tr>
                                                <!-- </span> -->
                                                </template>
                                                <template v-if="access.defaults">
                                                <!-- <span v-if="showDefaultedFigures"> -->
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/defendants/Reports_Details_Amounts.svg" alt="icon" class="mr-2">Received Default:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_received_default, "Not Set")}}</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="header-label"><img src="/static/svg/defendants/Reports_Details_Amounts.svg" alt="icon" class="mr-2">Received CTDA Default:</td>
                                                        <td><b>{{isEmptyWithDefault(account.amount_received_ctda_default, "Not Set")}}</b></td>
                                                    </tr>
                                                <!-- </span> -->
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                <!-- </template>
                                <template v-else>
                                    <modal container="seller-info-item" :style="content.seller_account_id == account.seller_account_id ? 'border:1px solid rgb(41, 171, 162)' : ''">
                                        <img :src="preview('payment_methods',account.payment_type)" onerror="this.src='/static/svg/Payment_Paypal.svg'" alt="icon">
                                        <h5 :style="content.seller_account_id == account.id? 'color:#29ABA2' : ''">
                                            <span class="mb-1">Payment Type: {{isEmpty(account.type)}}</span>Email/Name: {{isEmpty(account.name_email)}}<span>Account Number: {{isEmpty(account.number)}}</span>
                                        </h5>
                                    </modal>
                                </template> -->
                            </column>
                        </div-row>
                        <div-row v-else>
                            <column md="12"><h5>{{ index }}</h5></column>
                            <column :md="field.length == 1 ? '12' : x%2==0 ? isBothClosed? '6' : '4' : '6'" v-for="(field,x) in section" :key="x">
                                <modal container="seller-info-item" v-for="(part,y) in field" :key="y">
                                    <img :src="`/static/svg/${part[0]}.svg`" alt="icon">
                                    <h5>
                                        <template v-if="part[2] == 'Seller Store URL'">
                                            <a :href="isEmpty(part[1])" target="_blank">
                                                {{isEmpty(part[1])}}
                                            </a>
                                        </template>
                                        <template v-else>
                                            {{isEmpty(part[1])}}
                                        </template>
                                        <span>{{part[2]}}</span>
                                    </h5>
                                </modal>
                            </column>
                        </div-row>
                    </section>
                </template>
            </div>
        </template>
    </ar-modal>
</template>


<script>
    import GlobalComponents from '@/globalComponents.js'
    import axios from '../../../axiosMime'
    import { mapActions } from 'vuex'
    import { ucFirst, capitalize } from '@/utils/string'
    import { useSubStatusesStore } from '@/store/pinia/substatuses';
    import { useCommonStore } from '@/store/pinia/common';
    import { getQFlagId, isEmpty, getCurrencySymbol } from '@/utils/common'
    import { Role } from '@/utils/constants'

    /** Pinia Store */
    import { useReasonSelectionStore } from '../../../store/pinia/reasons-selection';
    import { useFeatureAccessStore } from '../../../store/pinia/feature-access';
    /** Pinia Store */

    export default {
        components: {
            ...GlobalComponents,
            ArReasonSelection: () => import('@/components/pages/verification/listing/ReasonsSelection'),
            ArLoaderinator: _ => import('@/components/layouts/loading/Loaderinator'),
        },
        async mounted() {
            this.access.readonly = await this.checkFeatureAccess([2])
            this.access.amounts = await this.checkFeatureAccess([3])
            this.access.defaults = await this.checkFeatureAccess([4])
            this.access.amountsDefaults = await this.checkFeatureAccess([3,4])
            this.access.all = await this.checkFeatureAccess([1,3,4])

            this.getRestrictedReasons();
            
            const vm = this

            $('#listing-modal').on('hidden.bs.modal', function() {
                vm.commonStore.isIndividualStatusChange = false
            })
        },
        data: function(){
            return {
                status : '',
                comment : '',
                show_remove_confirmation: false,
                // mark_as_buttons : {
                //     "monitoring" : ["Monitoring", "monitoring","For Monitoring","Still validating the accuracy"],
                //     "accepted" : ["Accepted", "accepted","Accept Listing","Suitable for a Law case"],
                //     "rejected" : ["Rejected", "rejected", "Reject Listing", "Dismiss as inadequate for filing"],
                //     "invalid" : ["Invalid", "invalid", "Mark as Invalid","Not conforming to valid specifications"],
                //     "haspo" : ["HasPotential", "haspo", "Mark as Has Potential", "Possibility for Qualification"],
                //     "recheck" : ["ForRework", "for-rework", "Mark as For Rework","Need some fixes"],
                //     "reworked" : ["Reworked", "reworked", "Mark as Reworked","Fixes have been implemented"],
                //     "forreview" : ["Qualified", "qualified", "Mark as For Review", "Suitable for a Law case"],
                //     "submitted" : ["Submitted", "submitted", "For Submission", "Ready to be submitted"],
                //     "for-internal-review" : ["InternalReview", "for-internal-review", "Mark as For Internal Review", "Needs further investigation"],
                //     "initial-review-completed" : ["InitialReviewCompleted", "initial-review-completed", "Mark as Initial Review Completed", "Initial review for this has been done"],
                //     "forcommission" : ["Commission", "for-commission", "For Commission", "Variable-pay for reward"],
                //     "removecommission" : ["RemoveCommission", "remove-commission", "Remove Commission", "Detach reward"]
                // },

                /* Changed Description
                * Deliverable Link below:
                * https://podio.com/apmcllccom/artemis/apps/deliverables/items/395
                * Changed Statuses are: For Review, Monitoring, Accepted, Submitted, Rejected, Cases
                **/ 

                mark_as_buttons : {
                    "recycled" : ["Recycled", "recycled", "Mark as Recycled", "Data are to be recycled"],
                    "accepted" : ["Accepted", "accepted","Accept Data","Data is confirmed by the client that it is indeed a counterfeit and approved to include in the filing."],
                    "monitoring" : ["Monitoring", "monitoring","For Monitoring","The client needs an in-depth analysis to approve or reject the data."],
                    "rejected" : ["Rejected", "rejected", "Reject Data", "Data is confirmed by the client or law firm that it is not suitable for filing."],
                    "invalid" : ["Invalid", "invalid", "Mark as Invalid","Not conforming to valid specifications"],
                    "haspo" : ["HasPotential", "haspo", "Mark as Has Potential", "Possibility for Qualification"],
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // "recheck" : ["ForRework", "for-rework", "Mark as For Rework","Need some fixes"],
                    // "reworked" : ["Reworked", "reworked", "Mark as Reworked","Fixes have been implemented"],
                    "forreview" : ["Qualified", "qualified", "Mark as For Review", "Need Client's Input if Approved, Rejected, or Monitoring."],
                    "submitted" : ["Submitted", "submitted", "For Submission", "Data that is ready for filing and approved by the client."],
                    "for-internal-review" : ["InternalReview", "for-internal-review", "Mark as For Internal Review", "Needs further investigation"],
                    "initial-review-completed" : ["InitialReviewCompleted", "initial-review-completed", "Mark as Initial Review Completed", "Initial review for this has been done"],
                    "forcommission" : ["Commission", "for-commission", "For Commission", "Variable-pay for reward"],
                    "removecommission" : ["RemoveCommission", "remove-commission", "Remove Commission", "Detach reward"]
                },
                status_change: false,
                selected_reasons: [],
                access: {
                    amounts        : false,
                    defaults       : false,
                    amountsDefaults: false,
                    all            : false,
                    readonly       : false
                },
                mergedEvidence      : '',
                allPredefinedReasons: {},
                predefinedReasons   : [],
                fetchingReasons     : false,
                isLoading           : false,
                isClicked_StatusHistory: false,
                isClicked_ChangedStatus: true,
                showMoreClientListingReview: false,
                copy_rid: false,
                reasonsWithRestrictedRoles: {},
                isHistoryReady: false,
                keys: {
                    "Accepted": "accepted",
                    "Rejected": "rejected",
                    "Monitoring": "monitoring",
                    "Recycled": "recycled",
                    "Invalid": "invalid",
                    "archived": "archived",
                    "Filed": "filed",
                    "Has Potential": "haspo",
                    "Qualified": "forreview",
                    "Submitted": "submitted",
                    "For Internal Review": "for-internal-review",
                    "Initial Review Completed": "initial-review-completed",
                },
            }
        },
        computed: {
            restrictedFeatures() {
                return useFeatureAccessStore().restrictedFeatures;
            },
            commonStore() {
                return useCommonStore()
            },
            subStatusesOnlyOptions() {
                const subs = this.subStatuses?.subs?.[this.content.status] ?? []
                return subs.filter(sub => sub.id)?.length ? subs?.filter(sub => sub.id != this.content.substatus_id) ?? [] : []
            },
            subStatusesStore() {
                return useSubStatusesStore()
            },
            markAsButtons() {
                const buttons = JSON.parse(JSON.stringify(this.mark_as_buttons))

                if (this.isClientListingReviewAndShowLess){
                    Object.keys(buttons).forEach(status => {
                        buttons[status][2] = buttons[status][0].match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ")
                    })
                }

                return buttons
            },
            subStatuses() {
                const subStatuses = this.subStatusesStore.subStatuses?.total_item_by_substatus ?? {}
                const subs = Object.entries(subStatuses)
                const keys = this.keys

                const temp = {}

                subs.forEach(([k,  v]) => {
                    if (typeof keys[k] == 'undefined')
                        return false

                    const sub_statuses = v?.filter(s => s.id)

                    temp[keys[k]] = [ ...(sub_statuses?.length ? v : []) ]
                })

                return { subs: subStatuses, changeStatus: temp }
            },
            showLeftCLRNav() {
                return this.isClientListingReviewAndShowLess && !(this.currentListingIndex == 0 && this.currentPage == 1)
            },
            showRightCLRNav() {
                return this.isClientListingReviewAndShowLess && !(this.currentListingIndex == (this.itemsPerPage - 1) && this.currentPage == this.maximumPage)
            },
            showEditButton() {
                return this.in_case
                    ? (!this.access.readonly || [7].includes(this.access_level()))
                    : [7].includes(this.access_level())
            },
            seller_name() {
                if (!this.in_case)
                    return this.content.seller[0] !== undefined ? this.content.seller[0].name : 'Seller Not Found'
                else
                    return this.content.case_seller[0] !== undefined ? this.content.case_seller[0].name : 'Seller Not Found'
            },
            seller_url() {
                return !this.in_case
                    ? this.content.seller[0] !== undefined ? this.content.seller[0].url : 'Not Set'
                    : this.content.case_seller[0] !== undefined ? this.content.case_seller[0].url : 'Not Set'
            }, 
            button_label() {
                // 'Show History'
                return this.case_restriction_allowance ? 'Change Status' : 'History'
            },
            user() {
                return JSON.parse(localStorage.getItem('lawfirm'))
            },
            case_restriction_allowance() {
                return !this.in_case || (this.in_case && !this.access.all);

                /* const isAllowedToDefaultedFigures    = !this.isFeatureRestricted('cm-text-defaulted-figures');
                const isAllowedToNonDefaultedFigures = !this.isFeatureRestricted('cm-text-non-defaulted-figures');
                const isAllowedToAllFigures          = isAllowedToDefaultedFigures && isAllowedToNonDefaultedFigures;

                return !this.in_case || (this.in_case && !isAllowedToAllFigures); */
            },
            campaign_id() {
                return this.$route.params.campaign_id
            },
            userGroundWorks() {
                return 814
            },
            isBothClosed() {
                let isClicked_StatusHistory = this.isClicked_StatusHistory
                let isClicked_ChangedStatus = this.isClicked_ChangedStatus
                if(!isClicked_ChangedStatus && !isClicked_StatusHistory){
                    return true
                }
                return false
            },
            isClientListingReview() {
                // TODO: change to client access_level = 6
                // return this.user.access_level == 6 && ((this.$route.name == "listingreview" && this.content.qflag_id == 3) || (this.$route.name == "lawfirmlisting" && ["For Review", "Qualified", "Has Potential", "Monitoring", "Accepted", "Submitted", "Rejected", "Initial Review Completed"].includes(this.$route.params.status)))

                return !this.isFeatureRestricted('cm-modal-client-review') && ((this.$route.name == "listingreview" && this.content.qflag_id == 3) || (this.$route.name == "lawfirmlisting" && ["For Review", "Qualified", "Has Potential", "Monitoring", "Accepted", "Submitted", "Rejected", "Initial Review Completed"].includes(this.$route.params.status)));
            },
            isClientListingReviewAndShowLess() {
                return this.isClientListingReview && !this.showMoreClientListingReview
            },
            changeStatusRestrictionName() {
                const changeStatusRestriction = {
                    'has potential'           : 'cm-status-change-in-has-potential',
                    'for review'              : 'cm-status-change-in-for-review',
                    'initial review completed': 'cm-status-change-in-initial-review-completed',
                    'for internal review'     : 'cm-status-change-in-for-internal-review',
                    monitoring                : 'cm-status-change-in-monitoring',
                    accepted                  : 'cm-status-change-in-accepted',
                    submitted                 : 'cm-status-change-in-submitted',
                    rejected                  : 'cm-status-change-in-rejected',
                    recycled                  : 'cm-status-change-in-recycled',
                    filed                     : 'cm-status-change-in-filed'
                };

                const status = this.$route?.params?.status?.toLocaleLowerCase();

                return changeStatusRestriction[status];
            },
            hasStatusChangeOptions() {
                if (this.isFeatureRestricted(this.changeStatusRestrictionName)) {
                    return false;
                }

                const keys = Object.keys(this.mark_as_buttons)
                return this.mark_as.filter(m => keys.includes(m)).length > 0
            },
            generalHistory() {
                const { status } = this.$route.params;
                const isInCases = status.toLocaleLowerCase() === 'cases';
                const isInFiled = status.toLocaleLowerCase() === 'filed';
                const isCmStatusHistoryRestricted = this.$featureAccess.isRestricted('cm-text-status-history');

                if ((isInCases || isInFiled) && isCmStatusHistoryRestricted) {
                    return [];
                }

                const regex = /<li><b>Amount Received Default: (0|[1-9]\d*)(\.\d+)?<\/b><\/li>/g;
                const statusChangeCommentRegex = /<li><b>Others:<\/b><p class='ml-2'>(.*?)<p><\/li>/g;
                const listTagRegex = /<li[^>]*>(.*?)<\/li>/g;

                const generalHistory = (this.content.general_history ?? [])
                    .map(history => {
                        // history.text = this.$featureAccess.isRestricted('cm-text-amount-received-default') 
                        //     ? history.text.replace(regex, '') 
                        //     : history.text;

                        let historyText = history?.text

                        if (this.$featureAccess.isRestricted('cm-text-amount-received-default'))
                            historyText = historyText?.replace(regex, '')

                        if (this.$featureAccess.isRestricted('cm-text-status-change-comment')) {
                            if (historyText.includes('with the following reasons:')) {
                                historyText = historyText?.replace(statusChangeCommentRegex, '')
    
                                if (historyText.replace(listTagRegex, '')?.trim() === '')
                                    historyText = historyText.replace('with the following reasons:', '')
                            }
                        }

                        history.text = historyText

                        return history;
                    });

                return generalHistory;
            },
            isInFiledTab() {
                return this.$route.params.status == "Filed"
            },
            /* showDefaultedFigures() {
                return !this.isFeatureRestricted('cm-text-defaulted-figures');
            },
            showNonDefaultedFigures() {
                return !this.isFeatureRestricted('cm-text-non-defaulted-figures');
            }, */
        },
        props: {
            in_case : {
                value: Boolean,
                default: false
            },
            loading : {
                value: Boolean,
                default: true
            },
            case_details : {
                value: Boolean,
                default: false
            },
            // status_change : {
            //     value: Boolean,
            //     default: false
            // },
            content: {
                value: Object,
                default: {}
            },
            mark_as: {
                value: Array,
                default: ['comment']
            },
            mark_as_function: {
                value: Function,
                default: function(){}
            },
            edit_details_function: {
                value: Function,
                default: function(){}
            },
            reasons: {
                type: Object,
                default: () => ({})
            },
            status_changing: {
                type: Boolean,
                default: false
            },
            validListing: {
                type: Object,
                default: () => ({})
            },
            qflagStatus : {
                type: String,
                default: ''
            },
            currentListingIndex: {
                default: 0
            },
            currentPage: {
                default: 0
            },
            maximumPage: {
                default: 0
            },
            itemsPerPage: {
                default: 0
            },
            showSuccessConfirmation: {
                default: false
            },
            successMessage: {
                default: ""
            },
            setPredefinedReasons: {
                default: () => ({})
            }
        },
        methods: {
            ...mapActions(['checkFeatureAccess']),
            updateStatus(status, comment, id, selected_reasons) {
                this.commonStore.isIndividualStatusChange = true
                this.mark_as_function(status, comment, id, selected_reasons)
            },
            getListingCurrencySymbol() {
                const currency = this.content.listing_info?.currency?.split('-')[0]
                return getCurrencySymbol((currency || "").trim())
            },
            copytoClipboard(str = "", prop_name = 'listing id') {
                if (str != "") {
                    let modal = []
                    let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                    const el = document.createElement('textarea')
                    el.value = str
                    el.setAttribute('readonly', '')
                    el.style.position = 'absolute'
                    el.style.left = '-9999px'
                    container[modal.length ? 0 : "body"].appendChild(el)
                    el.select()
                    document.execCommand('copy')
                    container[modal.length ? 0 : "body"].removeChild(el)

                    // call the snackbar when copied
                    this.$parent.$parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
                }
            },
            isCopying(status) {
                this.copy_rid = status
            },
            cleanDetails(details) {
                return details.split("<span><b>Researcher:</b>")[0]
            },
            preview: function(what,name,link=false){
                if(what == 'campaigns'){
                    return this.$parent.$parent.getImage('campaigns', name)
                } else if (what == 'platforms') {
                    return this.$parent.$parent.getImage('platforms', name)
                } else if(what == 'listing'){
                    let filepath = `${process.env.VUE_APP_URL}/files/`

                    if (link == true)
                        return filepath + name

                    if (typeof name == 'undefined') return ""

                    if (name.includes("Missing")) {
                        var datum = name.split('|')
                        datum = datum[0].trim().split('.')

                        return ["png", "jpg", "jpeg"].includes(datum[1].trim().toLowerCase()) 
                            ? '/static/svg/IMG_BROKEN.svg'
                            : '/static/svg/PDF_BROKEN.svg'
                    } else {
                        var ext = name.split('.')

                        if (name != "") {
                            // return value while evaluating condition
                            return ["png", "jpg", "jpeg"].includes(ext[1].trim().toLowerCase()) // if filepath is image
                                ? name != "" // if 'name' is not null
                                    ? filepath + name // true: return filename with filepath
                                    : '/static/svg/IMG Default.svg' // false: return IMG Default
                                : '/static/svg/PDF Default.svg' // false: return PDF Default
                        }

                        return '/static/svg/IMG_BROKEN.svg'
                    }
                }
                return `/static/svg/Payment_${name}.svg`
            },
            errorPreview(image) {                
                var ext = image.split('.')

                return ["png", "jpg", "jpeg"].includes(ext[ext.length - 1].trim().toLowerCase())
                    ? '/static/svg/IMG_BROKEN.svg'
                    : '/static/svg/PDF_BROKEN.svg'
            },
            getPreview(preview1, preview2) {
                if ((preview1 != "" && typeof preview1 != 'undefined')) {
                    if (!preview1.includes("Missing"))
                        return preview1
                }

                return preview2 != "" && typeof preview2 != 'undefined' 
                    ? preview2 
                    : preview1 || "image.jpg"
            },
            file: function(value){
                return `${process.env.VUE_APP_URL}/${value}`
            },
            ucFirst(string) {
                if(typeof string == 'undefined'){
                    return ''
                }
                return string?.trim()?.charAt(0).toUpperCase() + string?.trim()?.slice(1) || '';
            },
            name: function(user) {
                if (user === undefined) {
                    return 'Not Set';
                }

                const firstName = this.ucFirst(user.first_name);
                const lastName = this.ucFirst(user.last_name);

                const name = `${firstName} ${lastName}`;

                if (this.$featureAccess.isAllowed('cm-text-internal-users-as-nas')) {
                    return name;
                }

                if (user.role === Role.Client || user.role === Role.Lawyer) {
                    return name;
                }

                return 'NAS';
            },
            isEmpty: function(value){
                if(Array.isArray(value) && value.length == 0) {
                    return []
                } else if(typeof value === 'undefined' || value === null || value == ''){
                    return 'Not Set'
                }
                return value
            },
            isEmptyWithDefault(value, defaultValue) {
                return (value?.trim() || '') == '' || value == null || value == undefined ? defaultValue : value
            },
            sections: function(){
                let to_return = {}
                if(this.in_case){
                    // to_return["Payment Accounts"] = this.content.case_seller_accounts.length ? this.content.case_seller_accounts : []
                    to_return["Payment Accounts"] = this.content.seller[0] != null ? this.content.seller[0].seller_accounts : []
                }
                to_return["Data Information"] =
                [
                        [
                            ["SellerInfo_Platform", this.content.platform, "Platform"],
                            ["Trademark_Claim", this.content.claimtype, "Claim Type"],
                            ["ListingInfo_History", this.content.listing_info.transaction_history, "Transaction History"],
                        ],
                        [
                            ["ListingInfo_SellerURL", this.seller_url, "Seller Store URL"],
                            ["ListingInfo_ItemType", this.content.listing_info.item_type_id, "Item Type"],
                            ["ListingInfo_SampleDummy",
                            this.content.listing_info.dummy_account ? this.content.listing_info.dummy_account[0] ? this.content.listing_info.dummy_account.da_name : null : null,
                            "Dummy Account Used"],
                        ]
                ]
                to_return["Infringement Indicators"] = [
                        "I.Reasons_Check", this.content.listing_info.reasons.filter(reason => reason != null), ""
                    ]
                to_return["Seller Contact Information"] = [
                        [
                            ["SellerInfo_ContactPerson", ((this.content.seller[0] || {}).seller_info || {}).contact_person || "Not Set", "Contact Person"],
                            ["Contact_Mobile", ((this.content.seller[0] || {}).seller_info || {}).mobile_no || 'Not Set', "Mobile Number"],
                            ["SellerInfo_Contact", ((this.content.seller[0] || {}).seller_info || {}).telephone || 'Not Set', "Telephone Number"],
                            ["SellerInfo_Email", ((this.content.seller[0] || {}).seller_info || {}).e_mail || 'Not Set', "Defendant Email"],
                        ],
                        [
                            // ["SellerInfo_ContactPerson", (((this.content.seller[0] || {}).seller_info || {}).seller_alias || 'Not Set').replace(/⁞/gi,','), "Alias"],
                            ["SellerInfo_ContactPerson", this.content.aliases?.length ? this.content.aliases.map(alias=> alias.alias).toString() : "-----", "Alias"],
                            ["Conversation_Date", ((this.content.seller[0] || {}).seller_info || {}).first_contact_date || 'Not Set', "First Contact Date"],
                            ["Contact_VPN", ((this.content.seller[0] || {}).seller_info || {}).vpn_location || 'Not Set', "VPN Location Used"],
                            ["Conversation_Method", ((this.content.seller[0] || {}).seller_info || {}).conversation_method || 'Not Set', "Conversation Methods"],
                        ],
                        [
                            ["Conversation_Script", ((this.content.seller[0] || {}).seller_info || {}).script || 'Not Set', "Script"]
                        ]
                    ]
                if(!this.in_case){
                    to_return["Payment Accounts"] = this.content.seller[0] != null ? this.content.seller[0].seller_accounts : []
                }
                to_return["Seller Location"] = [
                        [
                            ["SellerInfo_Location",((this.content.seller[0] || {}).seller_info || {}).country  || 'Not Set',"Country"],
                            ["SellerInfo_Location",((this.content.seller[0] || {}).seller_info || {}).region  || 'Not Set',"Region"],
                        ],
                        [
                            ["SellerInfo_Address",((this.content.seller[0] || {}).seller_info || {}).address || 'Not Set',"Address"],
                            ["SellerInfo_Shipping",
                            (((this.content.seller[0] || {}).seller_info || {}).ship_to_us == null) || ((this.content.seller[0] || {}).seller_info || {}).ship_to_us || 0 === '1'
                            ? 'Will Ship to US' : 'Does not Ship to US',
                            "Shipping Info"],
                        ]
                    ]
                to_return["Data Price Details"] = [
                        [
                            ["Transaction_ItemPrice",this.content.listing_info.listing_price ? this.content.listing_info.listing_price+".00" : null,"Initial Price"],
                            ["Transaction_Currency",this.content.listing_info.currency,"Price Currency"],
                            ["Trademark_Brand", 
                            this.content.listing_info.brand_on_item_image == 1 ? "Seen on Item Image" : "Not Seen on Item Image",
                            "Brand Name"]
                        ],
                        [
                            ["Transaction_ItemPrice",this.content.listing_info.listing_price_max ? this.content.listing_info.listing_price_max+".00" : null,"Maximum Price"],
                            ["Transaction_Unit",this.content.listing_info.listing_price_unit,"Price Unit"],
                            ["Trademark_Brand", 
                            this.content.listing_info.logo_on_item_image == 1 ? "Seen on Item Image" : "Not Seen on Item Image",
                            "Logo"]
                        ],
                    ]
                to_return["Purchase Details"] = [
                        [
                            ["Conversation_Purchase", this.content.listing_info.purchase_type, "Purchase Type"],
                            ["Purchase_TransacNum", this.content.listing_info.transaction_number, "Transaction Number"],
                            ["SellerInfo_ShippingLocation", this.content.listing_info.listing_location, "Data Location"],
                        ],
                        [
                            ["Purchase_Payment", this.content.listing_info.payment_platform, "Payment Platform"],
                            ["Transaction_ItemPrice", 
                            this.content.listing_info.purchase_price ? this.content.listing_info.purchase_price+".00" : null, 
                            "Purchase Price"],
                            ["SellerInfo_ShippingLocation", this.content.listing_info.shipping_location, "Shipping Location"],
                        ],
                    ]
                return to_return
            },
            uploadedEvidence(evidences) {
                for (let key in evidences) {
                    if (key.toLowerCase().split(" ").some(r => ["merge"].includes(r))) {
                        this.mergedEvidence = {"image":evidences[key],"type":key}
                        break;
                    }
                }

                // commented Marvin - 04/09/2021
                // let rearranged   = []
                // let product      = []
                // let store        = []
                // let conversation = []
                // let checkout     = []
                // let add_evidence = []
                // let asin         = []
                // let google       = []
                // let cover        = []
                // // console.log(evidences)
                // for(let key in evidences){
                //         let index = key.toString().toLowerCase()
                    
                //     if (key.toLowerCase().split(" ").some(r => ["preview", "merge"].includes(r)))
                //         cover.push({"image":evidences[key],"type":key})
                    
                //     // console.log(key.toLowerCase())

                //     // if(index == "preview"){
                //     //     rearranged[0] = {"image":evidences[key],"type":key}
                //     // }else if(index == "lf review"){

                //     //     rearranged[1] = {"image":evidences[key],"type":key}
                //     // }else if(index.includes("product")){

                //     //     product.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("store")){

                //     //     store.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("conversation")){

                //     //     conversation.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("checkout")){

                //     //     checkout.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("additional")){

                //     //     add_evidence.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("asin")){

                //     //     asin.push({"image":evidences[key],"type":key})
                //     // }else if(index.includes("google")){

                //     //     google.push({"image":evidences[key],"type":key})
                //     // }else{
                //     //     cover.push({"image":evidences[key],"type":key})
                //     // }
                // }
                // return  [...rearranged,...product,...store,...conversation,...checkout,...add_evidence,...asin,...google,...cover].filter(image=>{
                //         return image != null
                //     })
            },
            getStatusIcon(id, field) {
                if (field == "qflag_id")
                    return this.$parent.$parent.getQFlagIcon(parseInt(id))
                
                return ""
            },
            switchStatusAndHistory(flag, btn_clicked) {
                this.status        = ''
                this.status_change = flag
                
                document.querySelector('.modal.show').scrollTo(0, 0)
                setTimeout(() => $('.researcher-name').hide(), 0)
                

                /*
                *   btn_clicked = changed_status
                *   "Changed Status" in listings "History" in Cases
                * 
                *   btn_clicked = status_history
                *   "Status History" in listings "Case Details" in Cases
                *   
                *   in_case ? inside Case Page : inside Listings 
                */

                if(btn_clicked == 'changed_status'){
                    this.isClicked_StatusHistory = false
                }else {
                    this.isClicked_ChangedStatus = false
                }
                
                
                

            },
            getTheRightSellerAccounts() {
                if (this.$route.params.status == 'Cases' && this.$route.params.case_id != '0') {
                    return typeof this.content.case_seller_accounts != 'undefined' && this.content.case_seller_accounts != null 
                        ? this.content.case_seller_accounts[0] != null
                            ? this.content.case_seller_accounts[0].seller_accounts
                            : []
                        : []
                }

                return this.content.seller[0] != null ? this.content.seller[0].seller_accounts : []
            },
            showCommentsTextbox(reasons = []) {
                if (reasons.length == 0 || this.selected_reasons.includes('Others:'))
                    return true
                
                this.comment = ''
                return false
            },
            access_level(){
                return JSON.parse(localStorage.getItem('lawfirm')).access_level
            },
            removeGroup() {
                this.$parent.$parent.showSnackbar(`Removing...`, true);
                let headers = JSON.parse(localStorage.getItem("headers"))                
                axios.delete(`${process.env.VUE_APP_URL}/group_listings/${this.content.group.group_listing_id}`, headers)
                .then(response => {
                    this.$parent.$parent.showSnackbar(`Removed successfully`, false, 'success', 'fas fa-trash');
                    // this.content.group = {};
                    this.show_remove_confirmation = false;
                    this.$emit('remove_group');
                })
                .catch(ex => {
                    this.$parent.$parent.showSnackbar(`Something went wrong. Please contact system admin`, false, 'invalid', 'fas fa-times-circle')
                })
            },
            gettype(object) {
                return typeof object;
            },
            toggleStatus(status) {
                useReasonSelectionStore().resetReasonsSelectionStore();
                this.subStatusesStore.currentSelectedSubStatus = null

                if (this.status == status) {
                    this.predefinedReasons = []
                    this.status = ''

                    $('.a-modal-status-history-con .btn').removeClass('active focus')

                    return
                }

                $('.a-modal-status-history-con .sub-statuses-container .btn.sub-status').removeClass('active')

                setTimeout(() => {
                    $('.a-modal-status-history-con .status-change-options .btn.active').siblings().find('.sub-statuses-container .btn').first().addClass('active')
                })


                this.status = status
                this.subStatusesStore.currentSelectedSubStatus = -1
            },
            removeSubStatusFocus() {
                // setTimeout(() => {
                //     $('.a-modal-status-history-con .btn.statusBorder.sub-status').removeClass('active focus')
                // })
            },
            toggleSubStatus(e, id, status) {
                useReasonSelectionStore().resetReasonsSelectionStore();
                
                this.subStatusesStore.currentSelectedSubStatus = null

                let p = e.target.parentElement
                let count = 0

                while (!$(p).hasClass('status-change-options') && count < 6) {
                    p = p.parentElement
                    count++
                }

                $(p).children().first().addClass('active focus')

                this.status = status
                this.subStatusesStore.currentSelectedSubStatus = id
            },
            toggleSubStatusOnly(e, id, status) {
                useReasonSelectionStore().resetReasonsSelectionStore();
                
                if (this.subStatusesStore.currentSelectedSubStatus == id) {
                    this.subStatusesStore.currentSelectedSubStatus = null
                    this.predefinedReasons = []
                    this.status = ''

                    $('.a-modal-status-history-con .btn').removeClass('active focus')

                    return
                }
                
                if (this.status != status)
                    this.predefinedReasons = []
                
                this.subStatusesStore.currentSelectedSubStatus = null

                let p = e.target.parentElement
                let count = 0

                while (!$(p).hasClass('status-change-options') && count < 6) {
                    p = p.parentElement
                    count++
                }

                $(p).children().first().addClass('active focus')
                $('.a-modal-status-history-con :not(.btn.statusBorder.sub-status)').removeClass('active focus')

                this.status = status
                this.subStatusesStore.currentSelectedSubStatus = id
            },
            async updateListingSubStatus() {
                if (this.subStatusesStore.currentSelectedSubStatus == null)
                    return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')

                if (!['accepted', 'initial-review-completed'].includes(this.status) || isInFiledTab) {
                    const hasNoReasons = this.$store.state.checked_reasons.length === 0
                        && this.$store.state.checked_subreasons.length === 0
                        && isEmpty(this.$store.state.checked_other_reason)
                    if (hasNoReasons)
                        return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                    if (!isEmpty(this.$store.state.checked_other_reason)) {
                        if (isEmpty(this.$store.state.other_reason_comment))
                            return this.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                    }
                }

                try {
                    const config = this.$session.get('lawfirm_headers')
                    config.headers["Allow-Checking-Bypass"] = 1
                    
                    const data = {
                        target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                        listing_ids: this.content.id,
                        comment: '',
                        reasons: this.setPredefinedReasons(),
                        is_same_status: true
                    }

                    await this.$http.patch(`/listings/multiple/statuschange/${this.content.qflag_id}`, data, config)

                    this.$parent.showSnackbar(`Successfully transferred listing to new substatus.`, false, 'success', 'fas fa-check')

                    this.$parent.getListings(false)
                    this.$parent.refreshCM_statuses()

                    $('.modal').modal('hide')
                } catch (e) {
                    console.log(e)
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                }
            },
            getPredefinedReasons(id) {
                const headers = this.$session.get('lawfirm_headers')
                this.$store.dispatch('resetPredefinedReasonState')

                // this.batch_change_status = id
                const qflag = this.$parent.$parent.getQFlag(id)
                if(this.allPredefinedReasons[qflag]?.length > 0) {
                    this.predefinedReasons = this.allPredefinedReasons[qflag]
                    this.fetchingReasons   = true
                    return
                }

                this.isLoading = true
                this.$http.get(`/predefined_reasons/${id}?page_size=100`, headers)
                    .then(response => {
                        if(response.status === 200) {
                            const result = response.data;

                            /* this.predefinedReasons = result.data
                                .filter(reason => reason.status && !isReasonRestricted(reason))
                                .map(reason => ({
                                    id     : reason.id,
                                    type   : reason.type,
                                    reason : reason.sub_reasons.length
                                        ? {[reason.reason] : reason.sub_reasons
                                            .filter(sub => sub.status && !isReasonRestricted(sub))
                                            .map(sub => ({id: sub.id, reason: sub.text}))}
                                        : reason.reason
                                })); */

                            const reasons = result.data;

                            const role = this.user?.role ?? this.user.access_level;
                            const isReasonRestricted = ({ restricted_roles }) => restricted_roles !== null && restricted_roles?.includes(role);

                            this.predefinedReasons = reasons.filter(reason => reason.status && !isReasonRestricted(reason))
                                .map(item => {
                                    const { id, type, severity_level, sub_reasons, reason, restricted_roles } = item;

                                    const getSubReasons = () => sub_reasons
                                        .filter(item => item.status && !isReasonRestricted(item))
                                        .map(item => ({ id: item.id, severity_level: item.severity_level, reason: item.text, restricted_roles: item.restricted_roles }));

                                    const mainReason = sub_reasons.length ? { [reason]: getSubReasons() } : reason;

                                    const WITH_SUBREASONS = 2;

                                    if (+type === WITH_SUBREASONS && mainReason[reason]?.length === 0) {
                                        return null;
                                    }

                                    return { id, type, severity_level, reason: mainReason, restricted_roles };
                                })
                                .filter(item => item !== null);

                            this.allPredefinedReasons[qflag] = this.predefinedReasons
                            this.fetchingReasons = true
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                    })
                    .finally(() => this.isLoading = false)
            },
            edit_listing_data() {
                $('.modal').modal('hide')
                this.$router.push({ name: 'lawfirmeditlisting', params: { campaign_id: this.content.campaign_id, lid: this.content.id }, query: { action: 'edit', lid: this.content.id, qflag_id: this.content.qflag_id }})
                // setTimeout(() => {
                //     $('#cm-edit-listing-modal').modal('show')
                // }, 500);
            },
            /**
             * @param { string } status
             */
             statusChangeFeatureName(status) {
                if (status === 'qualified') {
                    status = 'for-review';
                }

                if (status === 'submitted') {
                    status = 'for-submission';
                }

                if (status === 'haspo') {
                    status = 'has-potential';
                }

                return `cm-status-change-${status}`;
            },
            async getRestrictedReasons() {
                const reasonsRequest = await this.$http.get('/api/reasons?page_size=1000', this.$session.get('lawfirm_headers'));
                const reasons = reasonsRequest.data.data;

                const reasonsInfoRequest = await this.$http.get('/api/reasons-info?page_size=1000', this.$session.get('lawfirm_headers'));
                const reasonsInfo = reasonsInfoRequest.data.data;

                const role = this.user?.role ?? this.user.access_level;

                const isRestrictedToMyAccount = ({ restricted_roles }) => Array.isArray(restricted_roles) && restricted_roles.length !== 0 && restricted_roles.includes(role);

                const reasonsWithRestrictedRoles = reasons.filter(isRestrictedToMyAccount)
                    .reduce((accumulated, current) => [ current.id, ...accumulated ], []);

                const reasonsInfoWithRestrictedRoles = reasonsInfo.filter(isRestrictedToMyAccount)
                    .reduce((accumulated, current) => [ current.id, ...accumulated ], []);

                this.reasonsWithRestrictedRoles = {
                    main: reasonsWithRestrictedRoles,
                    sub: reasonsInfoWithRestrictedRoles
                };

                return;
            }
        },
        watch: {
            restrictedFeatures(restrictedFeatures) {
                const isCmButtonShowStatusHistory = restrictedFeatures.includes('cm-button-show-status-history');

                if (!isCmButtonShowStatusHistory) {
                    return;
                }

                this.isClicked_ChangedStatus = true;
                this.isClicked_StatusHistory = false;
            },
            loading() {
                if (this.loading == false) {
                    this.status_change            = false
                    this.status                   = ''
                    this.comment                  = ''
                    this.show_remove_confirmation = false
                    this.uploadedEvidence(this.content.evidences)
                }
            },
            status(val) {
                this.selected_reasons = []
                this.comment          = ''

                if(!isEmpty(val)) {
                    let status = val == 'haspo' ? 'has potential' : val
                    const qflagId = getQFlagId(status)
                    this.getPredefinedReasons(qflagId)
                }
            },
            content(value) {

                setTimeout(() => {
                    $('.researcher-name').hide()
                    $('.ver-only').hide()
                }, 100)

                if (this.in_case) {
                    setTimeout(() => {
                        this.isHistoryReady = true
                    }, 1000)
                }
            },
            isClicked_StatusHistory(value) {
                if (!value) {
                    return;
                }

                this.isHistoryReady = false;

                setTimeout(() => {
                    const reasons = document.querySelectorAll('[data-reason-id]');

                    for (const element of reasons) {
                        const reasonId = element.dataset.reasonId;

                        if (reasonId === '') {
                            continue;
                        }

                        const user = this.user;
                        const role = user?.role ?? user.access_level;

                        const isRestricted = this.reasonsWithRestrictedRoles.main.includes(+reasonId);

                        if (!isRestricted) {
                            continue;
                        }

                        element.style.display = 'none';
                    }

                    const reasonsInfo = document.querySelectorAll('[data-reason-info-id]');

                    for (const element of reasonsInfo) {
                        const reasonInfoId = element.dataset.reasonInfoId;

                        if (reasonInfoId === '') {
                            continue;
                        }

                        const user = this.user;
                        const role = user?.role ?? user.access_level;

                        const isRestricted = this.reasonsWithRestrictedRoles.sub.includes(+reasonInfoId);

                        if (!isRestricted) {
                            continue;
                        }

                        element.style.display = 'none';
                    }

                     this.isHistoryReady = true;
                }, 1000);

                return;
            },
            showMoreClientListingReview() {
                this.status = ""
                this.isClicked_ChangedStatus = true
            }
        }
    }
</script>

<style scoped>
    .empty-status-state {
        width: 100%;
        text-align:center;
        padding-top: 30px;
    }
    .group:hover .remove-btn {
        display: inline-block;
    }

    .remove-btn {
        cursor: pointer;
        display:none;
        height: 12px !important;
        width: 12px !important;
        position: inherit !important;
    }

    .remove-confirm {
        left: 180px;
        background: white;
        box-shadow: 0px 1px 6px #00000029;
        border: 1px solid #7070703D;
    }

    .evidence-notice {
        height: 144px;
        border: 1px dashed #3E88DA;
        border-radius: 10px;
        position: relative;
        padding: 20px 20px 20px 124px;
        background: #eef4fc;
        margin-bottom: 6px;
        margin-top: 30px;
    }

    .evidence-notice .notice-img {
        width: 85px;
        height: 104px;
        position: absolute;
        left: 21px;
        top: 50%;
        overflow: hidden;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border: 1px dashed transparent;
    }

    .evidence-notice .notice-img img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: auto;
        width: 440px;
    }

    .evidence-notice .notice-img:hover {
        opacity: 0.8;
        /* border-color: #3E88DA; */
        /* border-radius: 10px; */
        -webkit-transform: translateY(-50%) scale(1.1);
        transform: translateY(-50%) scale(1.1);
    }

    .evidence-notice h6 {
        background: #FF6565;
        border-radius: 14px;
        line-height: 25px;
        height: 24px;
        width: 75px;
        text-align: center;
        color: #ffff;
        font-size: 11px;
        margin-bottom: 10px;
    }

    .evidence-notice p {
        text-align: left;
        font-size: 14px;
        color: #000000CC;
    }

    .evidence-notice-alt {
        height: 144px;
        border: 1px solid #3E88DA;
        border-radius: 10px;
        position: relative;
        padding: 20px 20px 10px 93px;
        margin-bottom: 6px;
        margin-top: 30px;
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        word-break: break-word;
    }

    .evidence-notice-alt:hover {
        background: #3E88DA38;
    }

    .evidence-notice-alt .notice-img {
        width: 42px;
        height: 54px;
        position: absolute;
        left: 21px;
        top: 50%;
        overflow: hidden;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border: 1px dashed transparent;
    }

    .evidence-notice-alt .notice-img img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: auto;
        width: 100%;
    }

    .evidence-notice-alt h6 {
        background: #FF6565;
        border-radius: 14px;
        line-height: 25px;
        height: 24px;
        width: 75px;
        text-align: center;
        color: #ffff;
        font-size: 11px;
        margin-bottom: 10px;
    }

    .evidence-notice-alt p {
        text-align: left;
        font-size: 14px;
        color: #000000CC;
    }
    /* border for status change */

    .sub-statuses-container {
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 10px;
    }

/* --------- accepted and submitted --------- */
    .statusBorder {
        height: 80px !important;
        border: 1px solid transparent !important;
        padding: 14px 12px 12px 60px !important;
        cursor: pointer;
    }

    .statusBorder.sub-status {
        height: 36px !important;
        position: relative;
        width: 100%;
        padding-top: 22px !important;
        padding-bottom: 20px !important;
        padding-left: 80px !important;
        border-radius: 8px !important;
    }

    .statusBorder.sub-status.only {
        border: 1px solid #bdd6f1 !important;
    }

    .statusBorder.sub-status::before {
        content:"";
        position: absolute;
        top: 50%;
        left: 47px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #383A3D;
    }

    .statusBorder img {
        left: 20px;
    }


    .statusBorder.status-accepted {
        border: 1px solid #29ABA240 !important;
    }
    .statusBorder.status-accepted:hover {
        background-color: #DFF6F4;
        border: 1px solid transparent !important;
    }
    .statusBorder.status-monitoring {
        border: 1px solid #F3A17A42 !important;
    }
    .statusBorder.status-monitoring:hover {
        background-color: #F3A17A45;
        border: 1px solid transparent !important;
    }
    .statusBorder.status-rejected {
        border: 1px solid #F5686C6B !important;
    }
    .statusBorder.status-rejected:hover {
        background-color: #F5686C38;
        border: 1px solid transparent !important;
    }


    .statusBorder.status-accepted.active,
    .statusBorder.status-submitted.active {
        border: 1px solid #29ABA2 !important;
    }
    .statusBorder.status-accepted.active h5,
    .statusBorder.status-submitted.active h5{
        color: #29ABA2;
    }
/* --------- monitoring --------- */
    .statusBorder.status-monitoring.active {
        border: 1px solid #FF57228F !important;
    }
    .statusBorder.status-monitoring.active h5{
        color: #FF5722;
    }
/* --------- rejected --------- */
    .statusBorder.status-rejected.active {
        border: 1px solid #DC3C40 !important;
    }
    .statusBorder.status-rejected.active h5{
        color: #DC3C40;
    }

    .client-review-design .statusBorder.status-accepted.active,
    .client-review-design .statusBorder.status-submitted.active {
        background-color: #DFF6F4;
    }

    .client-review-design .statusBorder.status-monitoring.active {
        background-color: #F3A17A45;
    }

    .client-review-design .statusBorder.status-rejected.active {
        background-color: #F5686C38;
    }

/* --------- others --------- */
    .statusBorder.status-qualified.active,
    .statusBorder.status-researched.active,
    .statusBorder.status-haspo.active,
    .statusBorder.status-submitted.active,
    .statusBorder.status-reworked.active,
    .statusBorder.status-rework.active,
    .statusBorder.status-for-commission.active,
    .statusBorder.status-remove-commission.active,
    .statusBorder.status-recycled.active,
    .statusBorder.status-for-internal-review.active,
    .statusBorder.status-initial-review-completed {
        border: 1px solid #6693c7 !important;
    }
    .statusBorder.status-qualified.active h5,
    .statusBorder.status-researched.active h5,
    .statusBorder.status-haspo.active h5,
    .statusBorder.status-submitted.active h5,
    .statusBorder.status-reworked.active h5,
    .statusBorder.status-rework.active h5,
    .statusBorder.status-for-commission.active h5,
    .statusBorder.status-remove-commission.active h5,
    .statusBorder.status-recycled.active h5,
    .statusBorder.status-for-internal-review.active h5,
    .statusBorder.status-initial-review-completed.active h5 {
        color: #1a65bb;
    }

    .statusBorder.status-qualified,
    .statusBorder.status-researched,
    .statusBorder.status-haspo,
    .statusBorder.status-submitted,
    .statusBorder.status-reworked,
    .statusBorder.status-rework,
    .statusBorder.status-for-commission,
    .statusBorder.status-remove-commission,
    .statusBorder.status-recycled,
    .statusBorder.status-for-internal-review,
    .statusBorder.status-initial-review-completed {
        border: 1px solid #bdd6f1 !important;
    }
    .statusBorder.status-qualified:hover,
    .statusBorder.status-researched:hover,
    .statusBorder.status-haspo:hover,
    .statusBorder.status-submitted:hover,
    .statusBorder.status-reworked:hover,
    .statusBorder.status-rework:hover,
    .statusBorder.status-for-commission:hover,
    .statusBorder.status-recycled:hover,
    .statusBorder.status-for-internal-review:hover,
    .statusBorder.status-initial-review-completed:hover {
        background-color: #d9ebff;
        border: 1px solid transparent !important;
    }
    .statusBorder.status-qualified.active:hover,
    .statusBorder.status-researched.active:hover,
    .statusBorder.status-haspo.active:hover,
    .statusBorder.status-submitted.active:hover,
    .statusBorder.status-reworked.active:hover,
    .statusBorder.status-rework.active:hover,
    .statusBorder.status-for-commission.active:hover,
    .statusBorder.status-recycled.active:hover,
    .statusBorder.status-for-internal-review.active:hover,
    .statusBorder.status-initial-review-completed.active:hover {
        border: 1px solid #6693c7 !important;
        background-color: #b7d5f7;
    }

    .id-status {
        letter-spacing: 0.4px;
        color: #2D599E;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 6px;
        flex-wrap: wrap;
        width: calc(100% - 40px);
        margin: 7px 0 8px;
    }

    .misc-details {
        padding: 2px 6px 1px;
        border: 1px solid #2D599E;
        display: inline-block;
        border-radius: 4px;
    }

    .clr-status {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #595959;
        font-size: 10px;
        font-weight: 500;
        padding: 2px 5px 1px;
        border-radius: 4px;
        border: 1px solid #70707054;          
        cursor:auto;
        width: auto;
    }

    .clr-status img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
    }

    .clr-status.review {
        color: #fff;
        background: #3E88DA;
        border: 1px solid #3E88DA;
    }

    .clr-price b {
        color: #3E88DA;
        font-size: 32px;
    }

    .clr-price i {
        color: #E3707F;
        font-size: 24px;
        font-weight: bold;
        font-style: initial;
        text-transform: none;
    }

    .clr-price i.small {
        font-size: 16px;
    }

    .clr-price small {
        font-size: 14px;
        color: #383A3D;
        text-transform: lowercase;
    }

    .clr-price img {
        height: 22px;
        width: 22px;
        margin-right: 10px;
        position: relative;
        left: initial;
        margin-top: -25px;
        right: initial;
    }

    .client-review-con {
        padding-left: 40px;
    }

    .client-review-con .client-review-con-con {
        width: 450px;
        word-break: break-word;
    }

    .lf-review-preview .img-container {
        /* background: #e8f0fb; */
        min-height: 570px;
    }

    .lf-review-preview .img-container img {
        width: 100%;
    }

    .client-review-con .a-modal-seller-info {
        /* min-height: 250px; */
        min-height: 413px;
        overflow: hidden;
    }

    .client-review-con .seller-details {
        overflow-wrap: break-word;
    }

    .client-review-con .a-toggle-btn {
        gap: 10px;
    }

    .a-modal-status-history-con.client-review-design {
        border: 0px;
        border-top: 1px solid #70707038;
        border-radius: 0;
        padding-left: 0;
    }

    .a-modal-status-history-con.client-review-design .status-change-header {
        font-size: 13px;
        color: #595959;

    }

    .client-review-design .statusBorder {
        height: auto !important;
        /* width: 95px; */
        width: 30%;
        padding: 5px 2px 5px!important;
        border-radius: 10px !important;
    }

    .client-review-design .statusBorder img {
        /* left: 0;
        right: 0;
        top: 20px;
        margin: 0 auto; */
        height: 12px;
        width: 18px;
        position: static;
        transform: none;
        -webkit-transform: none;
    }

    .client-design-navs {
        display: block;
        height: 49px;
        width: 49px;
        padding: 8px;
        text-align: center;
        background: #777777;
        color: #fff;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 100;
        font-size: 24px;
        border-radius: 100%;
        border: 1px solid #fff;
        opacity: .4;
    }

    .client-design-navs:hover {
        opacity: 1;
    }

    .client-design-navs.left {
        left: -20px;
        padding-right: 14px;
    }

    .client-design-navs.right {
        right: -20px;
        padding-left: 14px;
    }

    .a-modal-seller-info .seller-details img.client-design-seller-image {
        position: static;
    }

    .client-review-con-con .a-modal-seller-info .seller-details a:hover h5 {
        text-decoration: underline;
    }
</style>
